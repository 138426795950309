import { red } from "@mui/material/colors"
import { createTheme } from "@mui/material/styles"

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: "#F05A24",
    },
    secondary: {
      main: "#1A1A1A",
    },
    error: {
      main: red.A400,
    },
  },
  shape: {
    borderRadius: 8,
  },
  spacing: 8,
})

export default theme
