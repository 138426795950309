import * as React from "react"
import AppBar from "@mui/material/AppBar"
import { Box, Container, ThemeProvider, Toolbar, Typography } from "@mui/material"
import theme from "../themes/default_theme"

interface IProps {
  children: any
  data: any
}

/**
 * Default layout for a full page.
 *
 * @param {IProps} props see IProps for details.
 * @class
 */
const DefaultLayout: React.FunctionComponent<IProps> = (props: IProps) => {
  const { children, data } = props

  const version = data?.site?.siteMetadata?.version !== undefined ? data.site.siteMetadata.version : "not set"

  return (
    <Box>
      <ThemeProvider theme={theme}>
        <AppBar position="fixed">
          <Toolbar>
            <Typography variant="h6" component="h1">
              Firefly Inventory
            </Typography>
          </Toolbar>
        </AppBar>
        <Toolbar />

        <Container sx={{ mt: 3 }}>{children}</Container>

        <Toolbar />
        <AppBar position="fixed" color="default" sx={{ top: "auto", bottom: 0 }}>
          <Toolbar variant="dense">
            © Firefly Inventory {new Date().getFullYear()} | release: {version}
          </Toolbar>
        </AppBar>
      </ThemeProvider>
    </Box>
  )
}

export default DefaultLayout
