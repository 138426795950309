import * as React from "react"
import DefaultLayout from "../app/shared/layouts/DefaultLayout"
import { Container } from "@mui/material"

/**
 * Page displayed when there is no route.
 *
 * @param {any} props whatever needs to be passed to the layout.
 * @class
 */
const NotFound: React.FunctionComponent<any> = (props: any) => {
  return (
    <DefaultLayout {...props}>
      <Container>
        <p>You are trying to visit a page that does not exist. What does it mean to exist?</p>
      </Container>
    </DefaultLayout>
  )
}

export default NotFound
